import React from "react";
import "../../App.css";
import ConfSchedule from "../Schedule";

function Schedule() {
  return (
    <>
      <ConfSchedule />
    </>
  );
}

export default Schedule;
