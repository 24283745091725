import React from "react";
import "../../App.css";
import ConfLocation from "../ConfLocation";

function Location() {
  return (
    <>
      <ConfLocation />
    </>
  );
}

export default Location;
