import React, { useState, useEffect } from "react";
import App from "../../../../App";
import CommitteePage from "../../../CommitteePage";

function JCC2() {
  return (
    <>
      <CommitteePage
        src={require("../../../images/committees/crisis/jcc cbi.jpg")}
        committee="JCC: Central Bureau of Investigation"
        skill="Skill Level: Novice"
        description="Coming Soon!"
        chair1="Arhant Ghanta"
        position1='Chair'
        bio11="Dear delegates,"
        bio12="It is with great excitement that I welcome you to the World Health Organization General Assembly Committee at UCBMUN XXIX! My name is Arhant and I am a fourth-year student double majoring in Molecular & Cellular Biology and Public Health, along with a minor in Political Economy. I have always loved both STEM and the humanities, and especially enjoy studying current events in global culture and politics in my free time."
        bio13="Outside of MUN, I am involved in genome editing research involving CRISPR-Cas proteins at the Innovative Genomics Institute here at Berkeley. I have also served on the board of two pre-health volunteering clubs on campus, the Kidney Disease Screening and Awareness Program (KDSAP) and Volunteer Health Interpreters Organization (VHIO).  In my free time, I enjoy reading, biking, cooking, and learning languages!"
        bio14="I wish you all the best of luck and cannot wait to meet you all in Berkeley in November. Please do not hesitate to reach out to me or Harsh with any questions!"
        bio15="Sincerely,"
        bio16="Arhant Ghanta"
        // bio17="Saranyu Nel"
        photo1={require("../../../images/headshots/dias/akash.png")}
        chair2="Harsh Kumbhat"
        position2='Crisis Director'
        bio21="bio approaching?"
        // bio22=" My name is Jade (she/her/hers) and I'm honored to be your Crisis Director for BEARMUNXXIV JCC Adani Group. I'm a junior from the Bay Area, double majoring in computer science and data science. I joined UCBMUN in my first semester at Cal, having previously served on Officer Board as the vice president of finance. In my free time, I enjoy biking, playing the piano and shooting hoops."
        // bio23="The Adani Group is one of India's largest multinational conglomerates, with operations in more industries than I can count. Founded by Gautam Adani in 1988, the group is headquartered in Ahmedabad, India. Its primary business sectors range from energy to agriculture to defense. With so many industries, so many people, and a little bit of controversy, this will be a committee to remember! I cannot wait to work with everyone and I hope everyone has a great time!"
        // bio24="Best,"
        // bio25="Jade Mon"
        // bio26="Crisis Director, JCC: Adani Group"
        photo2={require("../../../images/headshots/dias/harsh.png")}
      />
    </>
  );
}

export default JCC2;
