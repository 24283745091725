import React from "react";
import "../../App.css";
import Cards from "../Cards";
import "../Cards.css";

function Secretariat() {
  return (
    <>
      <Cards />
    </>
  );
}

export default Secretariat;
