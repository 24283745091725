import React, { useState, useEffect } from "react";
import App from "../../../../App";
import CommitteePage from "../../../CommitteePage";

function PJ() {
  return (
    <>
      <CommitteePage
        src={require("../../../images/committees/crisis/percy jackson.webp")}
        committee="Percy Jackson — The Heroes of Olympus"
        skill="Skill Level: Novice"
        description="Step into the world of gods, demigods, and mythological creatures in the Percy Jackson Committee at BEARMUNXXIV! Delegates will take on the roles of key characters from Rick Riordan's beloved series, navigating political and diplomatic crises between the Olympian gods, Camp Half-Blood, and rival factions. With ancient prophecies, divine conflicts, and world-altering quests at stake, delegates must negotiate alliances, solve ancient mysteries, and prevent catastrophic wars between gods and mortals. Strategy, teamwork, and mythological knowledge are essential as you embark on this epic journey. Will you be able to save both worlds from impending doom? The fate of Olympus is in your hands!"
        chair1="Jocelyn Yu"
        position1='Chair'
        bio11="Dear delegates,"
        bio12="My name is Jocelyn Yu, and "
        bio13="But if you're reading this because you think you might be one, "
        bio14="I am a fourth year student at Berkeley double majoring in psychology and molecular and
        cell biology with an emphasis in neurobiology. I have a keen interest in visiting museums,
        over-managed city parks, and concerts."
        bio15="Good luck!"
        bio16="Best,"
        bio17="Saranyu Nel"
        photo1={require("../../../images/headshots/dias/Saranyu.png")}
        chair2="Sam Wang"
        position2='Vice Chair'
        bio21="Hey all,"
        bio22="I'm Anousha Athreya, and I'm beyond excited to be your Vice Chair for Percy Jackson — The Heroes of Olympus this year! I'm a third-year majoring in Electrical Engineering and Computer Science and Data Science, with a minor in Public Policy. In the past, I served as a Chair for the My Way Killings Committee, on UCBMUN XXVIII Secretariat as the Director of Technology, and currently serve as the Generalized Head Delegate for the travel team. "
        bio23="At Berkeley, I currently work on security research, and serve on the Computing and Information Technology Academic Senate committee. I really enjoy watching mystery & detective shows, so let me know if you have any recommendations."
        bio24="Before and even in my current major, I have always loved to relax by reading science fiction, including Percy Jackson! With the new TV show out, we thought this would be a fun way to remember our childhood classic book series. I am so excited for this committee and I know all of you will be able to live up to the characters, making equally exciting and entertaining choices. I can't wait to see you all, good luck, and I look forward to meeting you next year!"
        bio25="Looking forward to meeting you all and chairing this committee! "
        bio26="Best,"
        bio27="Anousha Athreya"
        photo2={require("../../../images/headshots/dias/anousha.png")}
      />
    </>
  );
}

export default PJ;
